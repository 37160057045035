<template>
  <div class="contact-wrapper confirm-page success-page">
    <div class="page-header">
      <h2>{{ $t("contact.title_inquiry_completed") }}</h2>
      <img class="header-img" src="@/assets/icons/multiple.svg" alt="" />
    </div>
    <div class="success-wrapper">
      <div class="success-text">
        <div class="item first">
          <span>{{ $t("contact.description_thank_for_contact") }}</span>
          <span>{{ $t("contact.description_we_confirm_content") }}</span>
        </div>
        <div class="item">
          <span>{{ $t("contact.description_not_response") }}</span>
        </div>
      </div>
      <div class="contact-submit-btn back-to-index" @click="backToIndex()">
        <span>{{ $t("contact.button_redicrect_screen_top") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    linkToPage: {
      type: String,
      default: 'index',
    },
    contactForm: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getLinkToPage: {
      get() {
        return this.linkToPage;
      },
      set(value) {
        this.$emit('update:linkToPage', value);
      },
    },
    getContactForm: {
      get() {
        return this.contactForm;
      },
      set(value) {
        this.$emit('update:contactForm', value);
      }
    }
  },
  methods: {
    backToIndex() {
      this.getLinkToPage = 'index';
      this.getContactForm.selectedContactType = '';
      this.getContactForm.isCheck = false;
      this.getContactForm.contactData = '';
      this.getContactForm.isConfirmPage = false;
      this.$router.push({path: '/products'});
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/views/contact/styles/index.scss';
</style>
