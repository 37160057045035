import request from './base/request';

const contactApi = (data) => {
  return request
    .post('/contact', data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export { contactApi }